import classNames from "classnames";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { imageStripeDisplayStore } from "../stores/imageStripeDisplayStore.ts";
import { ImageActions } from "./ImageActions.tsx";
import { ImageViewModeButtons } from "./ImageViewModeButtons.tsx";

export const ActionsBar = () => {
  const { isImageStripeOpen } = imageStripeDisplayStore.useState();

  return (
    <div className="h-3xl px-md py-sm flex-row items-center bg-white justify-between">
      <ImageViewModeButtons />
      <ImageActions />
      <ClickableIcon
        name="ImageStripe"
        iconClassName={classNames(
          isImageStripeOpen
            ? "fill-gray-600 stroke-gray-600 hover:fill-gray-350 hover:stroke-gray-350"
            : "fill-gray-350 stroke-gray-350 hover:fill-gray-600 hover:stroke-gray-600",
        )}
        tooltip={{
          side: "top",
          content: isImageStripeOpen
            ? "Close the image stripe"
            : "Open the image stripe",
        }}
        onClick={() => {
          imageStripeDisplayStore.toggleImageStripe();
        }}
      />
    </div>
  );
};
