import { Tooltip } from "../../../../../components/Tooltip/Tooltip.tsx";
import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import { ImageTile } from "../../../components/ImageTile.tsx";
import { useSelectedAssetUuid } from "../../../hooks/useSelectedAssetUuid.ts";
import type { Asset } from "../../../types.ts";
import { ImageThumbnail } from "../components/ImageThumbnail/ImageThumbnail.tsx";

export const HistoryView = () => {
  const { selectedAssetUuid } = useSelectedAssetUuid();

  const { data: asset } = useAppQuery<Asset>({
    queryKey: `assets/${selectedAssetUuid}`,
    enabled: !!selectedAssetUuid,
  });

  return (
    <div className="h-full flex-row flex-fill items-end overflow-x-auto">
      {/* FIXME: Improve loading state */}
      {asset &&
        [...asset.ancestors, asset.content].map((item) => (
          <Tooltip
            key={item.uuid}
            side="top"
            align="center"
            arrowDisplayed
            delayDuration={0}
            content={
              <div className="h-[300px]">
                <ImageTile image={item} fit="height" />
              </div>
            }
            className="!p-xs !bg-gray-600 !rounded-[0px]"
          >
            <div key={item.uuid} className="h-full px-xs">
              <ImageThumbnail
                image={item}
                isSelected={false}
                isDisabled
                fit="height"
              />
            </div>
          </Tooltip>
        ))}
    </div>
  );
};
