import { fabric } from "fabric";
import { useEffect } from "react";
import { z } from "zod";
import { Form } from "../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../components/Form/FormContext.ts";
import { addNullInput } from "../../../../../../utils/validations.ts";
import { useBoard } from "../../../../hooks/useBoard.ts";
import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import {
  scalePromptStrength,
  unscalePromptStrength,
} from "../../../components/SettingsMenu/settingsScaleUtils.ts";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { StyleIntensitySection } from "../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { TextSection } from "../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import { isEmpty } from "../../components/BaseEditor/utils.ts";
import { generateObjectsMask } from "../../components/ImageEditor/utils.tsx";
import {
  MAGIC_DRAW_DEFAULT_SETTINGS,
  useMagicDrawSettings,
} from "./hooks/useMagicDrawSettings.ts";
import { useUploadAndGenerateMagicDraw } from "./hooks/useUploadAndGenerateMagicDraw.ts";
import { drawCanvasStore } from "./stores/drawCanvasStore.ts";

export const MagicDrawSettings = () => {
  const { mutate: drawMutate, isLoading: isGenerationLoading } =
    useUploadAndGenerateMagicDraw({});

  const { fabricCanvas } = drawCanvasStore.useState();

  return (
    <Form
      className="flex-col flex-fill"
      schema={zMagicDrawSettings}
      initialValues={{
        selectedAssetUuid: "",
        prompt: "",
        selectedStyleUuid: null,
        isCanvasEmpty: true,
        isSelectedImageSucceeded: false,
        initImageWidth: 1,
        initImageHeight: 1,
      }}
      onSubmit={async (values) => {
        if (fabricCanvas) {
          const imageURL = fabricCanvas.toDataURL({
            format: "jpeg",
            multiplier: values.initImageHeight / (fabricCanvas.height ?? 1),
          });
          const imageBlob = await (await fetch(imageURL)).blob();

          const maskBlob = await generateObjectsMask({ fabricCanvas });

          drawMutate({
            image: imageBlob,
            mask: maskBlob,
            prompt: values.prompt,
            height: values.initImageHeight,
            width: values.initImageWidth,
            styleUuid: values.selectedStyleUuid,
            assetUuid: values.selectedAssetUuid,
          });
        }
      }}
    >
      <MagicDrawSettingsFormContent
        fabricCanvas={fabricCanvas}
        isGenerationLoading={isGenerationLoading}
      />
    </Form>
  );
};

const zMagicDrawSettings = z.object({
  selectedAssetUuid: z
    .string({ required_error: "Please upload an image in the canvas" })
    .uuid(),
  selectedStyleUuid: addNullInput(z.string().uuid(), "Please select a style"),
  prompt: z.string().min(1, { message: "Please enter a prompt" }),
  isCanvasEmpty: z.boolean().refine((val) => !val, {
    message: "Please draw on the image",
  }),
  isSelectedImageSucceeded: z.boolean().refine((val) => val, {
    message: "Please wait for your image to be generated",
  }),
  initImageWidth: z.number({
    required_error: "Please upload an image in the canvas",
  }),
  initImageHeight: z.number({
    required_error: "Please upload an image in the canvas",
  }),
});
type MagicDrawSettingsValues = z.input<typeof zMagicDrawSettings>;

const MagicDrawSettingsFormContent = ({
  fabricCanvas,
  isGenerationLoading,
}: {
  fabricCanvas?: fabric.Canvas;
  isGenerationLoading: boolean;
}) => {
  const { board } = useBoard();
  const { magicDrawSettings, setMagicDrawSettings } = useMagicDrawSettings();
  const selectedAsset = useGetSelectedAsset();

  const { useValue, setValues, submit, useError } =
    useForm<MagicDrawSettingsValues>();
  const prompt = useValue((v) => v.prompt);
  const missingStyleError = useError((v) => v.selectedStyleUuid);
  const missingAssetError = useError((v) => v.selectedAssetUuid);
  const ImageNotSucceededError = useError((v) => v.isSelectedImageSucceeded);
  const isCanvasEmptyError = useError((v) => v.isCanvasEmpty);
  const isCanvasEmptyForSucceededImageError = ImageNotSucceededError
    ? undefined
    : isCanvasEmptyError;

  useEffect(() => {
    setValues({ selectedStyleUuid: board.last_used_style_uuid });
  }, [setValues, board.last_used_style_uuid]);

  useEffect(() => {
    setValues({ selectedAssetUuid: selectedAsset?.uuid });
  }, [setValues, selectedAsset?.uuid]);

  useEffect(() => {
    setValues({
      isSelectedImageSucceeded: selectedAsset?.image.status === "succeeded",
    });
  }, [setValues, selectedAsset?.image.status]);

  useEffect(() => {
    if (selectedAsset) {
      const img = new Image();
      img.src = selectedAsset.image.url;
      img.onload = () => {
        setValues({
          initImageWidth: img.width,
          initImageHeight: img.height,
        });
      };
    }
  }, [selectedAsset, setValues]);

  useEffect(() => {
    if (fabricCanvas) {
      const onAdd = () => setValues({ isCanvasEmpty: false });
      const onRemove = () =>
        setValues({ isCanvasEmpty: isEmpty(fabricCanvas) });

      fabricCanvas.on("object:added", onAdd);
      fabricCanvas.on("object:removed", onRemove);
      return () => {
        fabricCanvas.off("object:added", onAdd);
        fabricCanvas.off("object:removed", onRemove);
      };
    }
  }, [fabricCanvas, setValues]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  loraScale={magicDrawSettings.lora_scale}
                  onLoraScaleChange={(loraScale) =>
                    setMagicDrawSettings({
                      lora_scale: loraScale,
                    })
                  }
                  defaultLoraScale={MAGIC_DRAW_DEFAULT_SETTINGS.lora_scale}
                />
                <SliderSection
                  min={0}
                  max={100}
                  defaultValue={scalePromptStrength(
                    MAGIC_DRAW_DEFAULT_SETTINGS.prompt_strength,
                  )}
                  // XXX: If prompt strength is in between 0 and 0.5, the results are the same (really close to the initial image)
                  // we want to make prompt_strength to vary from 0.5 to 1 only but display a creativity param between 0 and 100
                  value={scalePromptStrength(magicDrawSettings.prompt_strength)}
                  onChange={(promptStrengthPercentage) =>
                    setMagicDrawSettings({
                      prompt_strength: unscalePromptStrength(
                        promptStrengthPercentage,
                      ),
                    })
                  }
                  sliderName="Creativity"
                  sliderInformationSection={
                    <div>
                      <span className="font-bold">Creativity</span> - Lower
                      values will lead to similar images. Higher values will
                      lead to more creative images.
                    </div>
                  }
                />
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={magicDrawSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    setMagicDrawSettings({ negative_prompt: negativePrompt })
                  }
                />
              </div>
            }
          />
        </div>
      }
      footer={
        <GenerationBar
          prompt={prompt}
          setPrompt={(newPrompt) => {
            setValues({ prompt: newPrompt });
          }}
          promptError={useError((v) => v.prompt)}
          isLoading={isGenerationLoading}
          placeholder="Describe what to generate in the drawn zone"
          onGenerate={submit}
          extraErrors={[
            isCanvasEmptyForSucceededImageError,
            missingAssetError,
            missingStyleError,
            ImageNotSucceededError,
          ].filter((error): error is string => error !== undefined)}
        />
      }
    />
  );
};
