import { useMemo } from "react";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { useAppMutation } from "../../../../../http/useAppMutation.ts";
import { FAVORITE_TAG_NAME } from "../../../constants.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import { useDeleteAssetOnBoard } from "../../../hooks/useDeleteAssetOnBoard.ts";
import { useGetSelectedAsset } from "../../../hooks/useGetSelectedAsset.ts";
import { informationMenuDisplayStore } from "../../../stores/informationMenuDisplayStore.ts";
import { DownloadButton } from "./DownloadButton.tsx";

export const ImageActions = () => {
  const selectedAsset = useGetSelectedAsset();

  const { board } = useBoard();
  const favoriteTag = useMemo(
    () => board.tags.find((it) => it.name === FAVORITE_TAG_NAME),
    [board.tags],
  );

  const addTag = useAppMutation({
    path: "tags/add-assets",
    invalidate: [`boards/${board.uuid}`],
  }).mutation;
  const removeTag = useAppMutation({
    path: "tags/remove-assets",
    invalidate: [`boards/${board.uuid}`],
  }).mutation;

  const setIsLiked = useAppMutation({
    path: "contents/set-is-liked",
    invalidate: [`boards/${board.uuid}`],
  }).mutation;
  const addOrRemoveFavoriteTag = () => {
    if (!favoriteTag || !selectedAsset) return;
    if (selectedAsset.is_in_favorite) {
      removeTag.mutate({
        asset_uuids: [selectedAsset.uuid],
        tag_uuid: favoriteTag.uuid,
      });
    } else {
      addTag.mutate({
        asset_uuids: [selectedAsset.uuid],
        tag_uuid: favoriteTag.uuid,
      });
    }
  };

  const { isInformationMenuOpen } = informationMenuDisplayStore.useState();

  const { mutate: deleteAssetMutation, isLoading: deleteAssetIsLoading } =
    useDeleteAssetOnBoard();

  if (selectedAsset === undefined) return null;
  return (
    <div className="h-full flex-fill flex-row-center">
      {favoriteTag && (
        <ClickableIcon
          name="Heart"
          iconClassName={
            selectedAsset.is_in_favorite
              ? "fill-pimento-red stroke-pimento-red"
              : ""
          }
          tooltip={{
            side: "top",
            content: selectedAsset.is_in_favorite
              ? "Remove from favorites"
              : "Add to favorites",
          }}
          onClick={() => {
            addOrRemoveFavoriteTag();
          }}
        />
      )}
      <ClickableIcon
        name="ThumbsUp"
        iconClassName={
          selectedAsset.image.is_liked === true ? "fill-pimento-blue" : ""
        }
        tooltip={
          selectedAsset.image.is_liked !== null
            ? {
                side: "top",
                content: "You can't change your feedback",
              }
            : { side: "top", content: "Give positive feedback" }
        }
        className="disabled:!opacity-100"
        isDisabled={selectedAsset.image.is_liked !== null}
        onClick={() => {
          setIsLiked.mutate({ uuid: selectedAsset.image.uuid, is_liked: true });
        }}
      />
      <ClickableIcon
        name="ThumbsDown"
        iconClassName={
          selectedAsset.image.is_liked === false ? "fill-pimento-red" : ""
        }
        tooltip={
          selectedAsset.image.is_liked !== null
            ? {
                side: "top",
                content: "You can't change your feedback",
              }
            : { side: "top", content: "Give negative feedback" }
        }
        className="disabled:!opacity-100"
        isDisabled={selectedAsset.image.is_liked !== null}
        onClick={() => {
          setIsLiked.mutate({
            uuid: selectedAsset.image.uuid,
            is_liked: false,
          });
        }}
      />
      <ClickableIcon
        name="Trash"
        tooltip={{ side: "top", content: "Delete" }}
        disabled={deleteAssetIsLoading || selectedAsset.deleted_at !== null}
        onClick={() => {
          deleteAssetMutation({
            uuids: [selectedAsset.uuid],
          });
        }}
      />
      <DownloadButton
        imagesToDownload={[
          {
            url: selectedAsset.image.url,
            uuid: selectedAsset.image.uuid,
          },
        ]}
      />
      <ClickableIcon
        name="Info"
        tooltip={{ side: "top", content: "Information" }}
        onClick={() =>
          informationMenuDisplayStore.toggleInformationMenuDisplayState()
        }
        iconClassName={
          isInformationMenuOpen ? "stroke-pimento-blue stroke-xl" : ""
        }
      />
    </div>
  );
};
