import classNames from "classnames";
import { useMemo, useState } from "react";
import { BaseButton } from "../../../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { useAppMutation } from "../../../../../http/useAppMutation.ts";
import { FAVORITE_TAG_NAME } from "../../../constants.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import type { BoardAsset } from "../../../types.ts";

export const AssetTagsSection = ({
  selectedAssets,
}: {
  selectedAssets: BoardAsset[];
}) => {
  const { board } = useBoard();
  const [newTagName, setNewTagName] = useState<string>("");

  const addContentsToTag = useAppMutation({
    path: "tags/add-assets",
    invalidate: [`boards/${board.uuid}`],
  }).mutation;
  const removeAssetsFromTag = useAppMutation({
    path: "tags/remove-assets",
    invalidate: [`boards/${board.uuid}`],
  }).mutation;
  const addNewTag = useAppMutation({
    path: "boards/add-tag",
    invalidate: [`boards/${board.uuid}`],
    onSuccess: (response: { data: { tag_uuid: string } }) =>
      addSelectedAssetToTag({ tagUuid: response.data.tag_uuid }),
  }).mutation;

  const createTag = () => {
    addNewTag.mutate({ board_uuid: board.uuid, tag_name: newTagName });
    setNewTagName("");
  };

  const addSelectedAssetToTag = ({ tagUuid }: { tagUuid: string }) => {
    addContentsToTag.mutate({
      asset_uuids: selectedAssets.map((asset) => asset.uuid),
      tag_uuid: tagUuid,
    });
  };

  const tagsFilterSelected = useMemo(
    () =>
      board.tags
        // Set Favorite at the first position for display purpose
        .filter((tag) => tag.name === FAVORITE_TAG_NAME)
        .concat(board.tags.filter((tag) => tag.name !== FAVORITE_TAG_NAME))
        .map((tag) => ({
          isSelected: selectedAssets.every((selectedAsset) =>
            selectedAsset.tags.some((it) => it.uuid === tag.uuid),
          ),
          tag,
        })),
    [board.tags, selectedAssets],
  );

  return (
    <div className="flex-col gap-md">
      <div className="flex-row flex-wrap gap-sm">
        {tagsFilterSelected.map((tag) => (
          // FIXME: Define and use secondary button instead
          <BaseButton
            key={tag.tag.uuid}
            className={classNames(
              tag.isSelected ? "text-white bg-gray-600" : "bg-gray-200",
              "rounded-sm text-semibold text-center px-md py-sm hover:bg-gray-350 hover:text-white",
            )}
            onClick={() => {
              if (tag.isSelected) {
                removeAssetsFromTag.mutate({
                  asset_uuids: selectedAssets.map((asset) => asset.uuid),
                  tag_uuid: tag.tag.uuid,
                });
              } else {
                addSelectedAssetToTag({ tagUuid: tag.tag.uuid });
              }
            }}
            name={tag.tag.name}
          >
            {tag.tag.name === FAVORITE_TAG_NAME ? (
              <div className="pb-sm pt-xs">
                <Icon
                  name="Heart"
                  size={14}
                  className={classNames(tag.isSelected ? "stroke-white" : "")}
                />
              </div>
            ) : (
              tag.tag.name
            )}
          </BaseButton>
        ))}
      </div>
      <div className="flex-row items-center justify-end gap-sm">
        <input
          autoFocus
          className="h-3xl flex-fill px-md py-sm resize-none rounded border text-base placeholder:text-placeholder"
          placeholder="Create new tag"
          value={newTagName}
          onKeyDown={(e) => {
            if (newTagName.isNotBlank() && e.key === "Enter") createTag();
          }}
          onChange={(event) => {
            setNewTagName(event.target.value);
          }}
        />
        <BaseButton
          onClick={() => {
            if (newTagName.isNotBlank()) createTag();
          }}
          className="h-3xl px-lg rounded border hover:bg-gray-600 hover:text-white"
          loading={addNewTag.isLoading}
        >
          Add
        </BaseButton>
      </div>
    </div>
  );
};
