import { useEffect } from "react";
import Masonry from "react-responsive-masonry";
import { useNavigate } from "react-router-dom";
import { GENERATE_TOOL, TRANSFER_TOOL } from "../../../../routes.ts";
import { MasonryAssetTile } from "../../components/AssetImageTile/MasonryAssetTile.tsx";
import { FAVORITE_TAG_NAME } from "../../constants.ts";
import { useBoard } from "../../hooks/useBoard.ts";
import { EmptyStateInformation } from "../components/EmptyStateInformation.tsx";

export const CreateWorkspace = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !window.location.pathname.includes(TRANSFER_TOOL) &&
      !window.location.pathname.includes(GENERATE_TOOL)
    ) {
      navigate(GENERATE_TOOL);
    }
  }, [navigate]);

  return <CreateWorkspaceImageGrid />;
};

const CreateWorkspaceImageGrid = () => {
  const { board } = useBoard();
  return (
    <div
      id="CreateWorkspaceGrid"
      className="p-xl flex-fill flex-col w-full overflow-auto"
    >
      {board.assets.length > 0 ? (
        <Masonry columnsCount={4} gutter="6px">
          {board.assets.map((asset) => (
            <MasonryAssetTile
              key={asset.uuid}
              asset={asset}
              favoriteTagUuid={
                board.tags.find((it) => it.name === FAVORITE_TAG_NAME)?.uuid
              }
            />
          ))}
        </Masonry>
      ) : (
        <EmptyStateInformation
          title="nothing here yet!"
          body="Use the generation bar to start creating."
        />
      )}
    </div>
  );
};
