import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import { useGetSelectedAssetInitImage } from "../../hooks/useGetSelectedAssetInitImage.ts";
import { imageViewModeStore } from "../../stores/imageViewModeStore.ts";
import { OverlapImageContainer } from "./OverlapImageContainer.tsx";
import { SingleImageContainer } from "./SingleImageContainer.tsx";

export const ImageContainer = () => {
  const selectedAsset = useGetSelectedAsset();
  const selectedImageInitImage = useGetSelectedAssetInitImage();
  const { viewMode } = imageViewModeStore.useState();

  return viewMode === "overlap" && selectedImageInitImage && selectedAsset ? (
    <OverlapImageContainer
      initImage={selectedImageInitImage}
      resultingImage={selectedAsset.image}
    />
  ) : (
    <SingleImageContainer image={selectedAsset?.image} />
  );
};
