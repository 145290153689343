import { fabric } from "fabric";
import type { IEvent } from "fabric/fabric-impl";
import { useEffect, useState } from "react";
import { z } from "zod";
import { Form } from "../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../components/Form/FormContext.ts";
import { Switch } from "../../../../../../components/Switch/Switch.tsx";
import { useUser } from "../../../../../../hooks/useUser.ts";
import { isUserAllowedTo } from "../../../../../../types.ts";
import { addNullInput } from "../../../../../../utils/validations.ts";
import { useBoard } from "../../../../hooks/useBoard.ts";
import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { QualityPresetWrappedSection } from "../../../components/SettingsMenu/QualityPresetWrappedSection.tsx";
import {
  scalePromptStrength,
  unscalePromptStrength,
} from "../../../components/SettingsMenu/settingsScaleUtils.ts";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { StyleIntensitySection } from "../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { TextSection } from "../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import { isEmpty } from "../../components/BaseEditor/utils.ts";
import {
  generateLassosMask,
  updatePathsFill,
} from "../../components/MaskEditor/utils.ts";
import { GenerativeFillCreativityStrengthPresetSection } from "./GenerativeFillCreativityStrengthPresetSection.tsx";
import {
  GENERATIVE_FILL_DEFAULT_SETTINGS,
  useGenerativeFillSettings,
} from "./hooks/useGenerativeFillSettings.ts";
import { useUploadAndGenerativeFillMutation } from "./hooks/useUploadAndGenerativeFillMutation.ts";
import { generativeFillCanvasStore } from "./stores/generativeFillCanvasStore.ts";

export const GenerativeFillSettings = () => {
  const { mutate: fillMutate, isLoading: fillIsLoading } =
    useUploadAndGenerativeFillMutation({});

  const { fabricCanvas } = generativeFillCanvasStore.useState();

  return (
    <Form
      className="flex-col flex-fill"
      schema={zGenerativeFillSettings}
      initialValues={{
        selectedAssetUuid: "",
        prompt: "",
        selectedStyleUuid: null,
        isCanvasEmpty: true,
        isSelectedImageSucceeded: false,
      }}
      onSubmit={async (values) => {
        if (fabricCanvas) {
          const maskBlob = await generateLassosMask({ fabricCanvas });
          fillMutate({
            mask: maskBlob,
            prompt: values.prompt,
            assetUuid: values.selectedAssetUuid,
            styleUuid: values.selectedStyleUuid,
          });
        }
      }}
    >
      <GenerativeFillSettingsFormContent
        fabricCanvas={fabricCanvas}
        isGenerationLoading={fillIsLoading}
      />
    </Form>
  );
};

const zGenerativeFillSettings = z.object({
  selectedAssetUuid: z
    .string({ required_error: "Please upload an image in the canvas" })
    .uuid(),
  selectedStyleUuid: addNullInput(z.string().uuid(), "Please select a style"),
  prompt: z.string().min(1, { message: "Please enter a prompt" }),
  isCanvasEmpty: z.boolean().refine((val) => !val, {
    message: "Please trace a zone on the image",
  }),
  isSelectedImageSucceeded: z.boolean().refine((val) => val, {
    message: "Please wait for your image to be generated",
  }),
});
type GenerativeFillSettingsValues = z.input<typeof zGenerativeFillSettings>;

const GenerativeFillSettingsFormContent = ({
  fabricCanvas,
  isGenerationLoading,
}: {
  fabricCanvas?: fabric.Canvas;
  isGenerationLoading: boolean;
}) => {
  const { user } = useUser();
  const { board } = useBoard();
  const { generativeFillSettings, setGenerativeFillSettings } =
    useGenerativeFillSettings();
  const selectedAsset = useGetSelectedAsset();

  const { useValue, setValues, submit, useError } =
    useForm<GenerativeFillSettingsValues>();
  const prompt = useValue((v) => v.prompt);
  const missingStyleError = useError((v) => v.selectedStyleUuid);
  const missingAssetError = useError((v) => v.selectedAssetUuid);
  let imageNotSucceededError = useError((v) => v.isSelectedImageSucceeded);
  let isCanvasEmptyError = useError((v) => v.isCanvasEmpty);
  if (imageNotSucceededError) isCanvasEmptyError = undefined;
  if (missingAssetError) imageNotSucceededError = undefined;

  useEffect(() => {
    setValues({ selectedStyleUuid: board.last_used_style_uuid });
  }, [setValues, board.last_used_style_uuid]);

  useEffect(() => {
    setValues({ selectedAssetUuid: selectedAsset?.uuid });
  }, [setValues, selectedAsset?.uuid]);

  useEffect(() => {
    setValues({
      isSelectedImageSucceeded: selectedAsset?.image.status === "succeeded",
    });
  }, [setValues, selectedAsset?.image.status]);

  useEffect(() => {
    if (fabricCanvas) {
      setValues({ isCanvasEmpty: true });
      const onAdd = () => setValues({ isCanvasEmpty: false });
      const onRemove = () =>
        setValues({ isCanvasEmpty: isEmpty(fabricCanvas) });

      fabricCanvas.on("object:added", onAdd);
      fabricCanvas.on("object:removed", onRemove);
      return () => {
        fabricCanvas.off("object:added", onAdd);
        fabricCanvas.off("object:removed", onRemove);
      };
    }
  }, [fabricCanvas, setValues]);

  const [advancedSectionOpen, setAdvancedSectionOpen] = useState(false);
  useEffect(() => {
    if (generativeFillSettings.creativity_strength_preset === "custom") {
      setAdvancedSectionOpen(true);
    }
  }, [generativeFillSettings.creativity_strength_preset]);

  // XXX: to change the selection opacity in fabric canvas
  useEffect(() => {
    if (fabricCanvas) {
      const fillColor = `rgba(2, 159, 245, ${generativeFillSettings.prompt_strength})`;

      const onCreate = (
        options: fabric.IEvent<MouseEvent> & { path: fabric.Path },
      ) => {
        const fabricPath = options.path;
        fabricPath.set("fill", fillColor);
        if (fabricPath.path) {
          fabricPath.set("path", [...fabricPath.path, ["Z"]] as fabric.Point[]);
        }
        const animateStroke = (path: fabric.Path) => {
          if (path.canvas) {
            path.animate("strokeDashOffset", "-=3", {
              duration: 100,
              onChange: path.canvas.renderAll.bind(fabricPath.canvas),
              onComplete: () => animateStroke(path),
            });
          }
        };
        animateStroke(fabricPath);
      };

      fabricCanvas.on("path:created", onCreate as (e: IEvent) => void);

      updatePathsFill({
        fabricCanvas,
        color: fillColor,
      });

      return () => {
        fabricCanvas.off("path:created", onCreate as (e: IEvent) => void);
      };
    }
  }, [fabricCanvas, generativeFillSettings.prompt_strength]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <SettingsSectionWrapper
            name="Creativity Strength"
            content={<GenerativeFillCreativityStrengthPresetSection />}
            infoContent={
              <ul className="list-disc p-md">
                <li>
                  Low Creativity is adapted for light modifications, results
                  will be close to your initial image.
                </li>
                <li>
                  High Creativity is adapted for strong modifications, results
                  can be very different from your initial image.
                </li>
              </ul>
            }
          />
          <QualityPresetWrappedSection
            value={generativeFillSettings.quality_preset}
            onChange={(preset) =>
              setGenerativeFillSettings({ quality_preset: preset })
            }
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            open={advancedSectionOpen}
            onOpenChange={setAdvancedSectionOpen}
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  loraScale={generativeFillSettings.lora_scale}
                  onLoraScaleChange={(loraScale) =>
                    setGenerativeFillSettings({
                      lora_scale: loraScale,
                    })
                  }
                  defaultLoraScale={GENERATIVE_FILL_DEFAULT_SETTINGS.lora_scale}
                />
                <SliderSection
                  min={0}
                  max={100}
                  // XXX: If prompt strength is in between 0 and 0.5, the results are the same (really close to the initial image)
                  // we want to make prompt_strength to vary from 0.5 to 1 only but display a creativity param between 0 and 100
                  value={scalePromptStrength(
                    generativeFillSettings.prompt_strength,
                  )}
                  onChange={(promptStrengthPercentage) =>
                    setGenerativeFillSettings({
                      prompt_strength: unscalePromptStrength(
                        promptStrengthPercentage,
                      ),
                      creativity_strength_preset: "custom",
                    })
                  }
                  sliderName="Creativity"
                  sliderInformationSection={
                    <div>
                      <span className="font-bold">Creativity</span> - Lower
                      values will lead to similar images. Higher values will
                      lead to more creative images.
                    </div>
                  }
                />
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={generativeFillSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    setGenerativeFillSettings({
                      negative_prompt: negativePrompt,
                    })
                  }
                />
                {user && isUserAllowedTo(user, "mode:debug") && (
                  <SettingsSectionWrapper
                    name="Debug mode"
                    content={
                      <div className="flex-col gap-md">
                        <SliderSection
                          min={0}
                          max={300}
                          defaultValue={
                            GENERATIVE_FILL_DEFAULT_SETTINGS.padding_mask_crop
                          }
                          value={generativeFillSettings.padding_mask_crop}
                          onChange={(paddingMaskCrop) =>
                            setGenerativeFillSettings({
                              padding_mask_crop: paddingMaskCrop,
                            })
                          }
                          sliderName={
                            <span className="font-semibold text-pimento-red">
                              Padding Mask Crop
                            </span>
                          }
                          displayPercentage={false}
                        />
                        <div className="flex-row w-full justify-between">
                          <span className="font-semibold text-pimento-red">
                            Auto patch
                          </span>
                          <Switch
                            value={generativeFillSettings.auto_patch}
                            onChange={(autoPatch: boolean) =>
                              setGenerativeFillSettings({
                                auto_patch: autoPatch,
                              })
                            }
                          />
                        </div>
                      </div>
                    }
                    className="px-0"
                  />
                )}
              </div>
            }
          />
        </div>
      }
      footer={
        <GenerationBar
          prompt={prompt}
          setPrompt={(newPrompt) => {
            setValues({ prompt: newPrompt });
          }}
          promptError={useError((v) => v.prompt)}
          sectionTitle="Replace by"
          buttonContent="Replace"
          placeholder="a chilli pepper with sunglasses"
          isLoading={isGenerationLoading}
          onGenerate={submit}
          extraErrors={[
            isCanvasEmptyError,
            missingAssetError,
            missingStyleError,
            imageNotSucceededError,
          ].filter((error): error is string => error !== undefined)}
        />
      }
    />
  );
};
