import classNames from "classnames";
import { useState } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Button } from "../../../components/Button/Button.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { STYLE_TYPES } from "../../constants.ts";
import type { Style, StyleType } from "../../types.ts";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";

export const StyleTypeForm = ({ style }: { style: Style }) => {
  const [styleType, setStyleType] = useState<StyleType>();

  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: ["styles", `styles/${style.uuid}`],
  }).mutation;

  return (
    <div className="relative flex-col-center flex-shrink overflow-y-auto pt-[80px] gap-[100px]">
      <ModelCreationCloseButton className="absolute top-16 right-16" />
      <span className="text-[38px] text-gray-900 font-burg text-center">
        what do you want to focus on?
      </span>
      <div className="flex-row w-full justify-center flex-wrap gap-xl">
        {STYLE_TYPES.map((it) => (
          <BaseButton
            key={it}
            onClick={() => setStyleType(it)}
            className={classNames(
              it === styleType ? "border-gray-600" : "hover:border-gray-350",
              "p-lg rounded-sm border-xl flex-col gap-2xl",
            )}
          >
            <div className="flex-row-center gap-md">
              {STYLE_TYPE_CONTENTS[it].firstExampleUrls.map((url) => (
                <Image
                  key={url}
                  className="w-[60px] h-[60px] border rounded-xs"
                  src={url}
                />
              ))}
            </div>
            <div className="flex-col text-lg font-semibold">
              {STYLE_TYPE_CONTENTS[it].title}
            </div>
          </BaseButton>
        ))}
      </div>
      <Button
        style={{ width: "300px" }}
        disabled={styleType === undefined}
        onClick={() => {
          updateStyle({
            uuid: style.uuid,
            type: styleType,
          });
        }}
      >
        Next
      </Button>
    </div>
  );
};

const STYLE_TYPE_CONTENTS: Record<
  StyleType,
  { title: string; firstExampleUrls: string[] }
> = {
  style: {
    title: "Aesthetic Style",
    firstExampleUrls: [
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/style_example2_1.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/style_example2_2.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/style_example2_3.png",
    ],
  },
  object: {
    title: "Object",
    firstExampleUrls: [
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/object_example2_1.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/object_example2_2.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/object_example2_3.png",
    ],
  },
  character: {
    title: "Character",
    firstExampleUrls: [
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/character_example1_1.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/character_example1_2.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/character_example1_3.png",
    ],
  },
};
