import { useState } from "react";
import { SizeObservableWrapper } from "../../../../../../components/SizeObservableWrapper/SizeObservableWrapper.tsx";
import { useGetSelectedAsset } from "../../../../hooks/useGetSelectedAsset.ts";
import { BaseImageContainer } from "../../components/ImageContainer/BaseImageContainer.tsx";
import { MaskEditor } from "../../components/MaskEditor/MaskEditor.tsx";
import { generativeFillCanvasStore } from "./stores/generativeFillCanvasStore.ts";

export const GenerativeFillTool = () => {
  const selectedAsset = useGetSelectedAsset();

  const [maxSize, setMaxSize] = useState<{ width: number; height: number }>();
  const { fabricCanvas } = generativeFillCanvasStore.useState();

  return (
    <SizeObservableWrapper
      className="flex-fill w-full flex-col-center"
      onSizeChange={({ width, height }) => setMaxSize({ width, height })}
    >
      <BaseImageContainer image={selectedAsset?.image}>
        {/* FIXME : compute the wanted initial size to avoid image flickering */}
        {maxSize !== undefined && (
          <MaskEditor
            initWidth={10}
            initHeight={10}
            fabricCanvas={fabricCanvas}
            onCanvasReady={(canvas) => {
              canvas.on("object:added", generativeFillCanvasStore.addObject);
              canvas.on(
                "object:removed",
                generativeFillCanvasStore.removeObject,
              );
              generativeFillCanvasStore.setFabricCanvas(canvas);
            }}
            backgroundImageUrl={selectedAsset?.image.url}
            maxWidth={maxSize.width}
            maxHeight={maxSize.height}
          />
        )}
      </BaseImageContainer>
    </SizeObservableWrapper>
  );
};
