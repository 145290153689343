import { useLayoutEffect } from "react";
import { Navigate, Route } from "react-router";
import { Routes } from "react-router-dom";
import { useOn } from "../../../../hooks/useOn.ts";
import {
  DRAW_TOOL,
  SELECT_TOOL,
  UPSCALE_TOOL,
  VARIATION_TOOL,
} from "../../../../routes.ts";
import { applyCurrentSearchParamsToPath } from "../../../../utils/url.ts";
import { useBoard } from "../../hooks/useBoard.ts";
import { useSelectedAssetUuid } from "../../hooks/useSelectedAssetUuid.ts";
import { useOpenStyleImageModal } from "../../ImageModal/useOpenStyleImageModal.ts";
import { informationMenuDisplayStore } from "../../stores/informationMenuDisplayStore.ts";
import { InformationMenu } from "../components/InformationMenu/InformationMenu.tsx";
import { ActionsBar } from "./ActionsBar/ActionsBar.tsx";
import { GenerationResultOptions } from "./components/GenerationResultOptions/GenerationResultOptions.tsx";
import { ImageContainer } from "./components/ImageContainer/ImageContainer.tsx";
import { HistoryView } from "./HistoryView/HistoryView.tsx";
import { ToolBarContent } from "./ToolBarContent.tsx";
import { GenerativeFillTool } from "./Tools/GenerativeFillView/GenerativeFillTool.tsx";
import { MagicDrawTool } from "./Tools/MagicDrawView/MagicDrawTool.tsx";

export const EditWorkspace = () => {
  const { board } = useBoard();
  const { selectedAssetUuid, setSelectedAssetUuid, clearSelectedAssetUuid } =
    useSelectedAssetUuid();

  useOn("keydown", (event) => {
    if (isOpenStyleImageModal) return;
    const selectedImageIndex = board.assets.findIndex(
      (asset) => asset.uuid === selectedAssetUuid,
    );
    if (
      event.key === "ArrowRight" &&
      selectedImageIndex < board.assets.length - 1
    ) {
      setSelectedAssetUuid(board.assets.at(selectedImageIndex + 1)!.uuid);
    }
    if (event.key === "ArrowLeft" && selectedImageIndex > 0) {
      setSelectedAssetUuid(board.assets.at(selectedImageIndex - 1)!.uuid);
    }
  });

  const { isOpenStyleImageModal } = useOpenStyleImageModal();
  const { isInformationMenuOpen } = informationMenuDisplayStore.useState();

  useLayoutEffect(() => {
    if (
      // XXX: If selectedImageUuid is null, we don't want to override the value,
      // because it means that we put it to that value on purpose, most likely
      // for an initial "empty" state coming from external link or homepage.
      selectedAssetUuid !== null &&
      !board.assets.some((asset) => asset.uuid === selectedAssetUuid)
    ) {
      board.assets
        .filter(
          (asset) =>
            asset.image.status === "succeeded" ||
            asset.image.status === "processing",
        )
        .let((assets) => {
          if (assets.length > 0) {
            setSelectedAssetUuid(assets[0].uuid);
          } else {
            clearSelectedAssetUuid();
          }
        });
    }
  }, [
    clearSelectedAssetUuid,
    selectedAssetUuid,
    board.assets,
    setSelectedAssetUuid,
  ]);

  return (
    <div className="flex-fill flex-col justify-end">
      <div className="flex-fill flex-row">
        <div className="flex-fill flex-col gap-2xl">
          <ToolBarContent />
          <div className="px-xl flex-fill flex-row gap-xl">
            {/* FIXME: Instead of defining a content view per tool, we should use a single component `ImageContainer` for all
           edit tools instead of redefining different ones per tools. To do so:
            - Merge canvas in draw / fill tool
            - Unify the use of canvas for all edit tools
            This will remove all flash when switching between tools */}
            <div className="flex-fill flex-col-center">
              <Routes>
                <Route path={VARIATION_TOOL} element={<ImageContainer />} />
                <Route path={DRAW_TOOL} element={<MagicDrawTool />} />
                <Route path={SELECT_TOOL} element={<GenerativeFillTool />} />
                <Route path={UPSCALE_TOOL} element={<ImageContainer />} />
                <Route
                  path="*"
                  element={
                    <Navigate
                      replace
                      to={applyCurrentSearchParamsToPath(SELECT_TOOL)}
                    />
                  }
                />
              </Routes>
            </div>
            <GenerationResultOptions />
          </div>
          <div className="flex-col gap-xs">
            <div className="w-full h-[70px] px-xs">
              <HistoryView />
            </div>
            <ActionsBar />
          </div>
        </div>
        {isInformationMenuOpen && <InformationMenu />}
      </div>
    </div>
  );
};
